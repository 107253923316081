import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBlogposts,
  getCategories,
  getLocations,
  getMenuCategories,
  getMenuItems,
  getPages,
  getProjects,
  getPromos,
  getSliders,
  getSpecials,
  getTestimonials,
  tryGetclients,
  tryGetusers,
} from '@thedavid/plitzredux/actions';

import SidebarContent from './SidebarContent';

const Sidebar = () => {
  const dispatch = useDispatch();
  const [showAdminNav, setShowAdminNav] = useState(false);
  const [showRestaurantNav, setShowRestaurantNav] = useState(false);

  useEffect(() => {
    dispatch(getBlogposts());
    dispatch(getPages());
    dispatch(tryGetusers());
    dispatch(getSliders());
    dispatch(getTestimonials());
    dispatch(getPromos());
    dispatch(getCategories());
    dispatch(tryGetclients());
    dispatch(getLocations());
    dispatch(getMenuCategories());
    dispatch(getMenuItems());
    dispatch(getSpecials());
    dispatch(getProjects());
  }, [dispatch]);

  const {
    Pages,
    Sliders,
    Promos,
    Testimonials,
    Blogposts,
    Categories,
    Login,
    Clients,
    Projects,
    Locations,
    MenuCategories,
    MenuItems,
    Specials,
  } = useSelector((state) => state);
  const { pages } = Pages;
  const { sliders } = Sliders;
  const { promos } = Promos;
  const { testimonials } = Testimonials;
  const { blogposts } = Blogposts;
  const { categories } = Categories;
  const { users, user } = Login;
  const { clients } = Clients;
  const { locations } = Locations;
  const { menuCategories } = MenuCategories;
  const { menuItems } = MenuItems;
  const { specials } = Specials;

  useEffect(() => {
    if (user?.isSuperAdmin) {
      setShowAdminNav(true);
    }

    if (user?.isSuperAdmin || user?.companyId === 'MariaBonita') {
      setShowRestaurantNav(true);
    }
  }, [user]);

  return (
    <React.Fragment>
      <div data-simplebar className='h-100'>
        <SidebarContent
          pages={pages}
          blogposts={blogposts}
          categories={categories}
          users={users}
          sliders={sliders}
          testimonials={testimonials}
          promos={promos}
          projects={Projects}
          clients={clients}
          locations={locations}
          menuCategories={menuCategories}
          menuItems={menuItems}
          specials={specials}
          showAdminNav={showAdminNav}
          showRestaurantNav={showRestaurantNav}
        />
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
  pages: PropTypes.array,
  blogposts: PropTypes.array,
  categories: PropTypes.array,
  users: PropTypes.array,
  clients: PropTypes.array,
  projects: PropTypes.array,
  sliders: PropTypes.array,
  promos: PropTypes.array,
  locations: PropTypes.array,
  menuItems: PropTypes.array,
  menuCategories: PropTypes.array,
  specials: PropTypes.array,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(Sidebar);
