import {
  ADD_PROJECT_FAILED,
  ADD_PROJECT_GO,
  ADD_PROJECT_TRY,
  CLEAR_CURRENT,
  EDIT_PROJECT_FAILED,
  EDIT_PROJECT_GO,
  EDIT_PROJECT_TRY,
  GET_APROJECT_FAILED,
  GET_APROJECT_GO,
  GET_APROJECT_TRY,
  GET_PROJECTS_FAILED,
  GET_PROJECTS_GO,
  GET_PROJECTS_TRY,
  REMOVE_PROJECT_FAILED,
  REMOVE_PROJECT_GO,
  REMOVE_PROJECT_TRY,
} from './actionTypes';

export const getProjects = () => {
  return {
    type: GET_PROJECTS_TRY,
  };
};

export const gettingProjects = (data) => {
  return {
    type: GET_PROJECTS_GO,
    payload: data,
  };
};

export const getProjectsFailed = (error) => {
  return {
    type: GET_PROJECTS_FAILED,
    payload: error,
  };
};

export const getProject = (link) => {
  return {
    type: GET_APROJECT_TRY,
    payload: link,
  };
};

export const gettingProject = (data) => {
  return {
    type: GET_APROJECT_GO,
    payload: data,
  };
};

export const getProjectFailed = (error) => {
  return {
    type: GET_APROJECT_FAILED,
    payload: error,
  };
};

export const clearCurrentProject = () => {
  return {
    type: CLEAR_CURRENT,
  };
};

export const addProject = (formData) => {
  return {
    type: ADD_PROJECT_TRY,
    payload: { formData },
  };
};

export const projectAdded = (data) => {
  return {
    type: ADD_PROJECT_GO,
    payload: data,
  };
};

export const addProjectFailed = (error) => {
  return {
    type: ADD_PROJECT_FAILED,
    payload: error,
  };
};

export const editProject = (formData) => {
  return {
    type: EDIT_PROJECT_TRY,
    payload: { formData },
  };
};

export const projectEdited = (data) => {
  return {
    type: EDIT_PROJECT_GO,
    payload: data,
  };
};

export const editProjectFailed = (error) => {
  return {
    type: EDIT_PROJECT_FAILED,
    payload: error,
  };
};

export const removeProject = (id) => {
  return {
    type: REMOVE_PROJECT_TRY,
    payload: id,
  };
};

export const removingProject = (data) => {
  return {
    type: REMOVE_PROJECT_GO,
    payload: data,
  };
};

export const removeProjectFailed = (error) => {
  return {
    type: REMOVE_PROJECT_FAILED,
    payload: error,
  };
};
