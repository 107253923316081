import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// Pages Component
import Activity from '../pages/Administration/Activity';
import Dashboard from '../pages/Dashboard';

// Post Types
import AllPages from '../pages/Pages/AllPages';
import AddPage from '../pages/Pages/AddPage';
import AllBlogposts from '../pages/Blogposts/AllBlogposts';
import AddBlogpost from '../pages/Blogposts/AddBlogpost';
import AllSliders from '../pages/Sliders/AllSliders';
import AddSlider from '../pages/Sliders/AddSlider';
import AllTestimonials from '../pages/Testimonials/AllTestimonials';
import AddTestimonial from '../pages/Testimonials/AddTestimonial';
import AllPromos from '../pages/Promos/AllPromos';
import AddPromo from '../pages/Promos/AddPromo';
import Categories from '../pages/Categories/Categories';
import AllProjects from '../pages/Projects/AllProjects';
import AddProject from '../pages/Projects/AddProject';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/FogetPassword';
import NotApproved from '../pages/Authentication/NotApproved';
import ChangePassword from '../pages/Authentication/ChangePassword';

// Users
import AllUsers from '../pages/Users/AllUsers';
import AddUser from '../pages/Users/AddUser';
import UserSettings from '../pages/Users/UserSettings';

// Clients
import AllClients from '../pages/Clients/AllClients';
import AddClient from '../pages/Clients/AddClient';

import EmailInbox from '../pages/Apps/Email/inbox';
import EmailRead from '../pages/Apps/Email/read';

// Restaurants
import AddLocation from '../pages/Restaurant/Locations/AddLocation';
import AllLocations from '../pages/Restaurant/Locations/AllLocations';
import AddMenuItem from '../pages/Restaurant/MenuItems/AddMenuItem';
import AllMenuItems from '../pages/Restaurant/MenuItems/AllMenuItems';
import AddMenuCategory from '../pages/Restaurant/MenuCategories/AddMenuCategory';
import MenuCategories from '../pages/Restaurant/MenuCategories/MenuCategories';
import AddSpecial from '../pages/Restaurant/Specials/AddSpecial';
import AllSpecials from '../pages/Restaurant/Specials/AllSpecials';

const authProtectedRoutes = [
  // Administration
  { path: '/activity', component: Activity },
  { path: '/all-clients', component: AllClients },
  { path: '/add-client', component: AddClient },

  // Post Types
  { path: '/all-pages', component: AllPages },
  { path: '/add-page', component: AddPage },
  { path: '/all-blogposts', component: AllBlogposts },
  { path: '/add-blogpost', component: AddBlogpost },
  { path: '/all-cta', component: AllSliders },
  { path: '/add-cta', component: AddSlider },
  { path: '/all-testimonials', component: AllTestimonials },
  { path: '/add-testimonial', component: AddTestimonial },
  { path: '/all-announcements', component: AllPromos },
  { path: '/add-announcement', component: AddPromo },
  { path: '/categories', component: Categories },
  { path: '/all-projects', component: AllProjects },
  { path: '/add-project', component: AddProject },

  // Users
  { path: '/all-users', component: AllUsers },
  { path: '/add-user', component: AddUser },
  { path: '/update-profile', component: UserSettings },

  // Restaurants
  { path: '/add-location', component: AddLocation },
  { path: '/all-locations', component: AllLocations },
  { path: '/add-menu-item', component: AddMenuItem },
  { path: '/all-menu-items', component: AllMenuItems },
  { path: '/add-menu-category', component: AddMenuCategory },
  { path: '/all-menu-categories', component: MenuCategories },
  { path: '/add-special', component: AddSpecial },
  { path: '/all-specials', component: AllSpecials },

  // Email & Email Templates
  { path: '/email-inbox', component: EmailInbox },
  { path: '/email-read', component: EmailRead },

  { path: '/dashboard', component: Dashboard },

  { path: '/', exact: true, component: () => <Navigate to='/dashboard' /> },
];

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/pages-register', component: Register },
  { path: '/not-approved', component: NotApproved },
  { path: '/change-password', component: ChangePassword },
];

export { authProtectedRoutes, publicRoutes };
