import {
  ADD_PROJECT_FAILED,
  ADD_PROJECT_GO,
  ADD_PROJECT_TRY,
  CLEAR_CURRENT,
  EDIT_PROJECT_FAILED,
  EDIT_PROJECT_GO,
  EDIT_PROJECT_TRY,
  GET_APROJECT_FAILED,
  GET_APROJECT_GO,
  GET_APROJECT_TRY,
  GET_PROJECTS_FAILED,
  GET_PROJECTS_GO,
  GET_PROJECTS_TRY,
  REMOVE_PROJECT_FAILED,
  REMOVE_PROJECT_GO,
  REMOVE_PROJECT_TRY,
} from './actionTypes';

const initialState = {
  projects: [],
  project: null,
  loading: false,
  error: null,
  msg: null,
};

const Projects = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS_TRY:
    case GET_APROJECT_TRY:
    case ADD_PROJECT_TRY:
    case EDIT_PROJECT_TRY:
    case REMOVE_PROJECT_TRY:
      return {
        ...state,
        loading: true,
      };

    case GET_PROJECTS_GO:
      return {
        ...state,
        projects: action.payload,
        loading: false,
      };

    case ADD_PROJECT_GO:
      console.log(action.payload);
      return {
        ...state,
        projects: [action.payload, ...state.projects],
        loading: false,
      };

    case EDIT_PROJECT_GO:
      return {
        ...state,
        projects: state.projects.map((project) =>
          project._id === action.payload._id ? action.payload : project
        ),
        loading: false,
      };

    case GET_APROJECT_GO:
      return {
        ...state,
        project: action.payload,
        loading: false,
      };

    case REMOVE_PROJECT_GO:
      return {
        ...state,
        projects: state.projects.filter((fld) => fld._id !== action.payload.id),
        msg: action.payload.msg,
        loading: false,
      };

    case GET_PROJECTS_FAILED:
    case GET_APROJECT_FAILED:
    case ADD_PROJECT_FAILED:
    case EDIT_PROJECT_FAILED:
    case REMOVE_PROJECT_FAILED:
      return {
        ...state,
        project: null,
        error: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        project: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default Projects;
