import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Paginator from 'react-hooks-paginator';
import PropTypes from 'prop-types';

// Parts
import Loader from '../../components/Loader';
import AddProject from './AddProject';
import EditorModal from '../../components/Editors/EditorModal';

// Actions
import {
  clearCurrent,
  getProjects,
  postActivity,
  removeProject,
} from '@thedavid/plitzredux/actions';

const AllProjects = () => {
  const dispatch = useDispatch();

  const projectLimit = 10;

  const [offset, setOffset] = useState(0);
  const [currentProject, setCurrentProject] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState('');

  const Projects = useSelector((state) => state.Projects);
  const { projects, loading, error, msg } = Projects;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    setCurrentData(
      projects &&
        projects
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(offset, offset + projectLimit)
    );
  }, [offset, projects]);

  const setToggle = (link) => {
    if (isOpen) {
      setIsOpen(false);
      setCurrentItem('');
      dispatch(clearCurrent());
    } else {
      setIsOpen(true);
      setCurrentItem(link);
    }
  };

  const deleteProject = (project) => {
    dispatch(
      postActivity({
        logtype: 'warning',
        logcontent: `The project <strong>${project.title}</strong> has been removed`,
        email: user ? user.email : '',
      })
    );
    dispatch(removeProject(project._id));
  };

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  const viewButton = (project) => {
    return (
      <Fragment>
        <UncontrolledTooltip placement='top' target={`visit-${project.link}`}>
          View Item
        </UncontrolledTooltip>
        <a
          id={`visit-${project.link}`}
          href={`${process.env.REACT_APP_FRONT_END}${project.link}`}
          className='btn waves-effect text-primary waves-light'
          target='_blank'
          rel='noopener noreferrer'
          style={{ fontSize: 20, padding: '0.1rem', margin: '0.5rem' }}>
          <i className='fa-light fa-eye'></i>
        </a>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isOpen && (
        <EditorModal isOpen={isOpen} toggle={setToggle}>
          <AddProject toggle={setToggle} link={currentItem} />
        </EditorModal>
      )}
      <div className='container-fluid plitz-cards'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='project-title-box'>
              <h1 className=''>Projects</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>All Projects</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {error ? <Alert color='danger'>{error}</Alert> : null}
            {msg ? <Alert color='warning'>{msg}</Alert> : null}
          </Col>
        </Row>

        <Row>
          {loading ? (
            <Loader />
          ) : currentData && currentData.length > 0 ? (
            currentData
              .sort((a, b) => b.position - a.position)
              .map((project) => (
                <Col key={project._id} md={12}>
                  <Card>
                    <Row>
                      <Col sm={12} md={3}>
                        <div
                          className='card-img-top'
                          style={{ maxHeight: 250 }}>
                          <img
                            className='img-fluid actual-image'
                            src={
                              project.featuredimg
                                ? `${process.env.REACT_APP_PUBLIC_URL}${project.featuredimg}`
                                : `${process.env.REACT_APP_PUBLIC_URL}uploads/big-placeholder.jpg`
                            }
                            alt={project.title}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={9}>
                        <CardBody>
                          <div className='card-container'>
                            <div className='card-status-area'>
                              {project.isLive ? (
                                <span className='badge badge-success'>
                                  Published
                                </span>
                              ) : (
                                <span className='badge badge-dark'>Draft</span>
                              )}
                            </div>
                            <div className='card-content-area'>
                              <h3 className='mt-0'>{project.title}</h3>
                              {project.location ? (
                                <h5 className='card-title  mt-0'>
                                  Project Location: {project.location}
                                </h5>
                              ) : null}
                              <div className='author-section'>
                                <small>Created by</small>
                                <h6 className='mb-0'>
                                  <div className='flex-horizontal'>
                                    <div className='avatar-sm rounded-circle mr-2'>
                                      <img
                                        src={`${
                                          process.env.REACT_APP_PUBLIC_URL
                                        }${project.user.photo ?? user.photo}`}
                                        alt={
                                          project.user.fullname ?? user.fullname
                                        }
                                        className='actual-image'
                                      />{' '}
                                    </div>
                                    <span>
                                      {project.user.fullname ?? user.fullname}
                                    </span>
                                  </div>
                                </h6>
                              </div>
                            </div>
                            <div className='card-actions-area'>
                              <div className='buttons-only'>
                                {viewButton(project)}
                                <Fragment>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={`EditTt-${project.link}`}>
                                    Edit
                                  </UncontrolledTooltip>
                                  <button
                                    id={`EditTt-${project.link}`}
                                    type='button'
                                    onClick={() => setToggle(project.link)}
                                    className='btn waves-effect text-primary waves-light'
                                    data-toggle='modal'
                                    data-target='#myModal'
                                    style={{
                                      fontSize: 20,
                                      padding: '0.1rem',
                                      margin: '0.5rem',
                                    }}>
                                    <i className='fa-light fa-edit'></i>
                                  </button>{' '}
                                </Fragment>
                                <Fragment>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={`RemoveFt-${project.link}`}>
                                    Remove
                                  </UncontrolledTooltip>
                                  <button
                                    id={`RemoveFt-${project.link}`}
                                    type='button'
                                    onClick={() => deleteProject(project)}
                                    className='btn waves-effect ml-2 text-danger waves-light'
                                    style={{
                                      fontSize: 20,
                                      padding: '0.1rem',
                                      margin: '0.5rem',
                                    }}>
                                    <i className='fa-light fa-trash'></i>
                                  </button>{' '}
                                </Fragment>
                              </div>
                              <span>
                                Last Modification:{' '}
                                <Moment format='MMMM DD, YYYY'>
                                  {project.updatedAt}
                                </Moment>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))
          ) : (
            <h4>There is no projects yet</h4>
          )}
        </Row>

        <Row>
          <Col sm={12}>
            {projects && (
              <Paginator
                totalRecords={projects.length}
                pageLimit={projectLimit}
                pageNeighbours={2}
                setOffset={setOffset}
                currentPage={currentProject}
                setCurrentPage={setCurrentProject}
                pageContainerClass='pagination'
                pageActiveClass='current-page'
                pageNextText={<i className='typcn typcn-chevron-right'></i>}
                pageNextClass='pagination-arrow'
                pagePrevText={<i className='typcn typcn-chevron-left'></i>}
                pagePrevClass='pagination-arrow'
              />
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

AllProjects.propTypes = {
  projects: PropTypes.array,
  user: PropTypes.object,
};

export default AllProjects;
