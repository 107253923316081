import React, { useEffect, Fragment, useState } from 'react';
import { Collapse } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import classname from 'classnames';
import { useSelector } from 'react-redux';

const Navbar = ({ menuOpen, setIsMenuOpened, windowWidth }) => {
  const location = useLocation();
  const [showAdminNav, setShowAdminNav] = useState(false);
  const [showRestaurantNav, setShowRestaurantNav] = useState(false);

  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    if (user && user.isSuperAdmin) {
      setShowAdminNav(true);
    } else {
      setShowAdminNav(false);
    }

    if (user?.companyId === 'Plitz7' || user?.companyId === 'MariaBonita') {
      setShowRestaurantNav(true);
    } else {
      setShowRestaurantNav(false);
    }
  }, [user]);

  useEffect(() => {
    let matchingMenuItem = null;
    let ul = document.getElementById('navigation');
    let items = ul.querySelectorAll('.nav-item a');
    let currentPath =
      process.env.NODE_ENV === 'production'
        ? `${location.pathname}`
        : `/td${location.pathname}`;

    for (var i = 0; i < items.length; ++i) {
      if (currentPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('active'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
          }
        }
      }
    }
    return false;
  };

  const emptyClick = (e) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <div className='topnav'>
        <div className='container-fluid'>
          <nav className='navbar navbar-light navbar-expand-lg topnav-menu'>
            <Collapse
              isOpen={menuOpen}
              onClick={(e) => {
                e.preventDefault();
                if (windowWidth < 992) {
                  setIsMenuOpened(true);
                }
              }}
              className='navbar-collapse'>
              <div>
                <ul id='navigation' className='navbar-nav'>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/dashboard'>
                      <i className='fa-light fa-house-blank mr-2'></i>Dashboard
                    </Link>
                  </li>

                  <li className='nav-item dropdown'>
                    <Link
                      className='nav-link dropdown-toggle arrow-none'
                      to='/#!'
                      onClick={(e) => emptyClick(e)}>
                      <i className='fa-light fa-folder-grid mr-2'></i>Main
                      Content
                    </Link>

                    <div
                      className={classname('dropdown-menu', {
                        show: menuOpen,
                      })}>
                      <div className='dropdown'>
                        <Link
                          to='/#!'
                          onClick={(e) => emptyClick(e)}
                          className='dropdown-item dropdown-toggle arrow-none'>
                          Pages <div className='arrow-down'></div>
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: menuOpen,
                          })}
                          aria-labelledby='topnav-pages'>
                          <Link to='/all-pages' className='dropdown-item'>
                            All Pages
                          </Link>
                          <Link to='/add-page' className='dropdown-item'>
                            Add Page
                          </Link>
                        </div>
                      </div>
                      <div className='dropdown'>
                        <Link
                          to='/#!'
                          onClick={(e) => emptyClick(e)}
                          className='dropdown-item dropdown-toggle arrow-none'>
                          CTAs <div className='arrow-down'></div>
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: menuOpen,
                          })}
                          aria-labelledby='topnav-sliders'>
                          <Link to='/all-cta' className='dropdown-item'>
                            All Items
                          </Link>
                          <Link to='/add-cta' className='dropdown-item'>
                            Add CTA
                          </Link>
                        </div>
                      </div>
                      <div className='dropdown'>
                        <Link
                          to='/#!'
                          onClick={(e) => emptyClick(e)}
                          className='dropdown-item dropdown-toggle arrow-none'>
                          Testimonials <div className='arrow-down'></div>
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: menuOpen,
                          })}
                          aria-labelledby='topnav-testimonials'>
                          <Link
                            to='/all-testimonials'
                            className='dropdown-item'>
                            All Testimonials
                          </Link>
                          <Link to='/add-testimonial' className='dropdown-item'>
                            Add Testimonial
                          </Link>
                        </div>
                      </div>
                      <div className='dropdown'>
                        <Link
                          to='/#!'
                          onClick={(e) => emptyClick(e)}
                          className='dropdown-item dropdown-toggle arrow-none'>
                          Blog <div className='arrow-down'></div>
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: menuOpen,
                          })}
                          aria-labelledby='topnav-posts'>
                          <Link to='/all-blogposts' className='dropdown-item'>
                            Blog Posts
                          </Link>
                          <Link to='/add-blogpost' className='dropdown-item'>
                            Add Post
                          </Link>
                          <Link to='/categories' className='dropdown-item'>
                            Categories
                          </Link>
                        </div>
                      </div>
                      <div className='dropdown'>
                        <Link
                          to='/#!'
                          onClick={(e) => emptyClick(e)}
                          className='dropdown-item dropdown-toggle arrow-none'>
                          Project <div className='arrow-down'></div>
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: menuOpen,
                          })}
                          aria-labelledby='topnav-projects'>
                          <Link to='/all-projects' className='dropdown-item'>
                            Projects
                          </Link>
                          <Link to='/add-project' className='dropdown-item'>
                            Add Project
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>

                  {showRestaurantNav && (
                    <li className='nav-item dropdown'>
                      <Link
                        className='nav-link dropdown-toggle arrow-none'
                        to='/#!'
                        onClick={(e) => emptyClick(e)}>
                        <i className='fa-light fa-store mr-2'></i>
                        Restaurant Content
                      </Link>

                      <div
                        className={classname('dropdown-menu', {
                          show: menuOpen,
                        })}>
                        <div className='dropdown'>
                          <Link
                            to='/#!'
                            onClick={(e) => emptyClick(e)}
                            className='dropdown-item dropdown-toggle arrow-none'>
                            Locations <div className='arrow-down'></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: menuOpen,
                            })}
                            aria-labelledby='topnav-locations'>
                            <Link to='/all-locations' className='dropdown-item'>
                              See All
                            </Link>

                            <Link to='/add-location' className='dropdown-item'>
                              Add New
                            </Link>
                          </div>
                        </div>

                        <div className='dropdown'>
                          <Link
                            to='/#!'
                            onClick={(e) => emptyClick(e)}
                            className='dropdown-item dropdown-toggle arrow-none'>
                            Menu <div className='arrow-down'></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: menuOpen,
                            })}
                            aria-labelledby='topnav-products'>
                            <Link
                              to='/all-menu-itmes'
                              className='dropdown-item'>
                              All Items
                            </Link>

                            <Link to='/add-menu-item' className='dropdown-item'>
                              Add Item
                            </Link>

                            <Link
                              to='/all-menu-categories'
                              className='dropdown-item'>
                              Categories
                            </Link>
                          </div>
                        </div>

                        <div className='dropdown'>
                          <Link
                            to='/#!'
                            onClick={(e) => emptyClick(e)}
                            className='dropdown-item dropdown-toggle arrow-none'>
                            Specials <div className='arrow-down'></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: menuOpen,
                            })}
                            aria-labelledby='topnav-specials'>
                            <Link to='/all-specials' className='dropdown-item'>
                              See All
                            </Link>

                            <Link to='/add-special' className='dropdown-item'>
                              Add New
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}

                  <li className='nav-item dropdown'>
                    <Link
                      className='nav-link dropdown-toggle arrow-none'
                      to='/#!'
                      onClick={(e) => emptyClick(e)}>
                      <i className='fa-light fa-square-sliders-vertical mr-2'></i>
                      Settings
                    </Link>

                    <div
                      className={classname('dropdown-menu', {
                        show: menuOpen,
                      })}>
                      <div className='dropdown'>
                        <Link
                          to='/#!'
                          onClick={(e) => emptyClick(e)}
                          className='dropdown-item dropdown-toggle arrow-none'>
                          Users <div className='arrow-down'></div>
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: menuOpen,
                          })}
                          aria-labelledby='topnav-users'>
                          <Link to='/all-users' className='dropdown-item'>
                            All Users
                          </Link>

                          <Link to='/add-user' className='dropdown-item'>
                            Create User
                          </Link>

                          <Link to='/update-profile' className='dropdown-item'>
                            My User Settings
                          </Link>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-6'>
                          <div>
                            <Link to='/activity' className='dropdown-item'>
                              Activity
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  {showAdminNav && (
                    <li className='nav-item dropdown'>
                      <Link
                        className='nav-link dropdown-toggle arrow-none'
                        to='/#!'
                        onClick={(e) => emptyClick(e)}>
                        <i className='fa-light fa-tasks mr-2'></i>
                        Administration
                      </Link>

                      <div
                        className={classname('dropdown-menu', {
                          show: menuOpen,
                        })}>
                        <div className='dropdown'>
                          <Link
                            to='/#!'
                            onClick={(e) => emptyClick(e)}
                            className='dropdown-item dropdown-toggle arrow-none'>
                            Clients <div className='arrow-down'></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: menuOpen,
                            })}
                            aria-labelledby='topnav-clients'>
                            <Link to='/all-clients' className='dropdown-item'>
                              All Clients
                            </Link>

                            <Link to='/add-client' className='dropdown-item'>
                              Create Client
                            </Link>
                          </div>
                        </div>

                        <div className='dropdown'>
                          <Link
                            to='/#!'
                            onClick={(e) => emptyClick(e)}
                            className='dropdown-item dropdown-toggle arrow-none'>
                            Announcements <div className='arrow-down'></div>
                          </Link>
                          <div
                            className={classname('dropdown-menu', {
                              show: menuOpen,
                            })}
                            aria-labelledby='topnav-announcements'>
                            <Link
                              to='/all-announcements'
                              className='dropdown-item'>
                              All Announcements
                            </Link>
                            <Link
                              to='/add-announcement'
                              className='dropdown-item'>
                              Add Announcement
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}

                  <li className='nav-item'>
                    <Link className='nav-link' to='/email-inbox'>
                      <i className='fa-light fa-envelope-dot mr-2'></i>Inbox
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </nav>
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
