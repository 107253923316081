export const GET_PROJECTS_TRY = 'GET_PROJECTS_TRY';
export const GET_PROJECTS_GO = 'GET_PROJECTS_GO';
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED';

export const GET_APROJECT_TRY = 'GET_APROJECT_TRY';
export const GET_APROJECT_GO = 'GET_APROJECT_GO';
export const GET_APROJECT_FAILED = 'GET_APROJECT_FAILED';

export const ADD_PROJECT_TRY = 'ADD_PROJECT_TRY';
export const ADD_PROJECT_GO = 'ADD_PROJECT_GO';
export const ADD_PROJECT_FAILED = 'ADD_PROJECT_FAILED';

export const EDIT_PROJECT_TRY = 'EDIT_PROJECT_TRY';
export const EDIT_PROJECT_GO = 'EDIT_PROJECT_GO';
export const EDIT_PROJECT_FAILED = 'EDIT_PROJECT_FAILED';

export const REMOVE_PROJECT_TRY = 'REMOVE_PROJECT_TRY';
export const REMOVE_PROJECT_GO = 'REMOVE_PROJECT_GO';
export const REMOVE_PROJECT_FAILED = 'REMOVE_PROJECT_FAILED';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';
