import React, { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Row,
  Col,
} from 'reactstrap';
import Loader from '../Loader';

const PromosCar = ({ plitzPromos, error }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    activeIndex === plitzPromos?.length - 1
      ? setActiveIndex(0)
      : setActiveIndex(activeIndex + 1);
  };

  const previous = () => {
    if (animating) return;
    activeIndex === 0
      ? setActiveIndex(plitzPromos?.length - 1)
      : setActiveIndex(activeIndex - 1);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (error && error !== '') || !plitzPromos || plitzPromos.length === 0 ? (
    <span>{error}</span>
  ) : (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      pause='hover'
      interval={9000}
      slide={true}
      className='plitz-promos'>
      <CarouselIndicators
        items={plitzPromos}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />

      {plitzPromos.length > 0 &&
        plitzPromos
          .sort((a, b) => a.order - b.order)
          .map((item) => (
            <CarouselItem
              onExiting={onExiting}
              onExited={onExited}
              key={item._id}
              slide={true}>
              <Row className='slider-container'>
                <Col sm={12} md={5}>
                  <div className='slider-image'>
                    <img
                      src={`https://thedavid.plitz7.com/${item.othersizes.tablet}`}
                      alt={item.title}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={7}
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='slider-info'>
                    <h6>{item.subtitle}</h6>
                    <h4>{item.title}</h4>
                    <div
                      className='slider-content'
                      dangerouslySetInnerHTML={{ __html: item.details }}></div>
                    {item.buttons
                      .sort((a, b) => a.position - b.position)
                      .map((btn) => (
                        <a
                          key={btn._id}
                          href={btn.link}
                          target='_blank'
                          rel='noreferrer noopener'
                          className={`btn ${
                            btn.primary ? 'btn-success' : 'btn-info'
                          } mb-1 mr-1`}>
                          <span>{btn.label ?? 'Learn More'}</span>

                          <i className='mdi mdi-arrow-right h5'></i>
                        </a>
                      ))}
                  </div>
                </Col>
              </Row>
            </CarouselItem>
          ))}
    </Carousel>
  );
};

export default PromosCar;
