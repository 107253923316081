import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes/';
import AppRoute from './routes/route';

// layouts
import VerticalLayout from './components/VerticalLayout/';
import HorizontalLayout from './components/HorizontalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import Loader from './components/Loader';

// Styling
// // SCSS
import './assets/scss/theme.scss';

// // CSS
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';

const App = () => {
  const userLogged = useSelector((state) => state.Login);
  const layout = useSelector((state) => state.Layout);

  const { isAuthenticated, user } = userLogged;

  /**
   * Returns the layout
   */
  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const layOut = getLayout();

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter
        future={{ v7_startTransition: true }}
        basename={process.env.NODE_ENV === 'development' ? '/td' : '/'}>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  isAuthProtected={false}
                  verified={user?.verified}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <AppRoute
                  path={route.path}
                  layout={layOut}
                  component={route.component}
                  verified={user?.verified}
                  isAuthProtected={true}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
