import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  ADD_PROJECT_FAILED,
  ADD_PROJECT_GO,
  ADD_PROJECT_TRY,
  EDIT_PROJECT_FAILED,
  EDIT_PROJECT_GO,
  EDIT_PROJECT_TRY,
  GET_APROJECT_FAILED,
  GET_APROJECT_GO,
  GET_APROJECT_TRY,
  GET_PROJECTS_FAILED,
  GET_PROJECTS_GO,
  GET_PROJECTS_TRY,
  REMOVE_PROJECT_FAILED,
  REMOVE_PROJECT_GO,
  REMOVE_PROJECT_TRY,
} from './actionTypes';

import {
  addProject,
  editProject,
  getAllProjectsApi,
  getProjectApi,
  removeProject,
} from '../helpers/projectApi';

function* theProjectsPull() {
  try {
    const response = yield call(getAllProjectsApi);

    yield put({ type: GET_PROJECTS_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_PROJECTS_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* oneProjectPull({ payload }) {
  try {
    const response = yield call(getProjectApi, payload);

    yield put({ type: GET_APROJECT_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_APROJECT_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* addingProject({ payload: { formData } }) {
  try {
    const response = yield call(addProject, formData);

    yield put({ type: ADD_PROJECT_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: ADD_PROJECT_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* editingProject({ payload: { formData } }) {
  try {
    const response = yield call(editProject, formData);

    yield put({ type: EDIT_PROJECT_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: EDIT_PROJECT_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* removingProject({ payload: id }) {
  try {
    const response = yield call(removeProject, id);

    yield put({ type: REMOVE_PROJECT_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: REMOVE_PROJECT_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* pullingProjects() {
  yield takeLatest(GET_PROJECTS_TRY, theProjectsPull);
}

function* pullingProject() {
  yield takeLatest(GET_APROJECT_TRY, oneProjectPull);
}

function* addProjectTry() {
  yield takeLatest(ADD_PROJECT_TRY, addingProject);
}

function* editingProjectTry() {
  yield takeLatest(EDIT_PROJECT_TRY, editingProject);
}

function* removingProjectTry() {
  yield takeLatest(REMOVE_PROJECT_TRY, removingProject);
}

function* ProjectsSaga() {
  yield all([
    pullingProjects(),
    pullingProject(),
    addProjectTry(),
    editingProjectTry(),
    removingProjectTry(),
  ]);
}

export default ProjectsSaga;
