export const catsData = {
  PlitzTemplateUno: [
    { label: 'Web Application', value: 'web-application' },
    { label: 'Mobile Application', value: 'mobile-application' },
    { label: 'Small Business Websites', value: 'small-business-website' },
    { label: 'E-Commerce Websites', value: 'e-commerce-website' },
    { label: 'JavaScript', value: 'javascript' },
    { label: 'React', value: 'react' },
    { label: 'Node', value: 'node' },
    { label: 'Next.JS', value: 'next-js' },
    { label: 'WordPress', value: 'wordpress' },
  ],
  AirpathGroup: [
    { label: 'Retail', value: 'retail' },
    { label: 'Hospital / Laboratories', value: 'hospital-laboratories' },
    { label: 'Commercial High Rise', value: 'commercial-high-rise' },
    { label: 'Hotels', value: 'hotels' },
    { label: 'Airports', value: 'airports' },
    { label: 'Government', value: 'government' },
    { label: 'Commissioning', value: 'commissioning' },
    { label: 'ENG / Consulting', value: 'eng-consulting' },
  ],
};
